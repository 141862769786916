import ApplicationController from '../application_controller';

class ContractDocumentInitializeController extends ApplicationController {

  connect() {

    super.connect();
    document.addEventListener(
      'stimulus-reflex:connected',
      () => this.stimulate('ContractDocumentReflex#initialize!'),
    );
  }

  disconnect() {

    super.disconnect();
    document.removeEventListener(
      'stimulus-reflex:connected',
      () => this.stimulate('ContractDocumentReflex#initialize!'),
    );
  }
}

export default ContractDocumentInitializeController;
