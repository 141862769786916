import ApplicationController from './application_controller';

import { Parser } from 'expr-eval';

class FormControlAdjustmentController extends ApplicationController {

  connect() {

    super.connect();

    this.calculate();
  }

  calculate() {

    this.outputTarget.value = Parser
      .evaluate(`${this.output}${this.operation}`)
      .toFixed(this.decimalPlaces);
  }

  get output() {

    const { value } = this.inputTarget;
    return value.length ? value.replace(',', '') : this.data.get('default');
  }

  get decimalPlaces() {

    return this.data.get('decimal-places') || 0;
  }

  get operation() {

    return this.data.get('operation') || '*1';
  }
}

FormControlAdjustmentController.debounces = [
  'calculate',
];

FormControlAdjustmentController.targets = [
  'input',
  'output',
];

export default FormControlAdjustmentController;
