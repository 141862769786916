// Based on the up-to-date version of
//  https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/toggle.js
//  so that the html markup won't need to change much once we update the lib.

import ApplicationController from './application_controller';

import { classList } from '../utils';

// Used to toggle the visibility of a fieldset (show/hide)
class ToggleController extends ApplicationController {

  connect() {

    super.connect();

    this.toggleClass = this.data.get('class') || 'hidden';
  }

  toggle(event) {

    return this.open ? this.hide(event) : this.show(event);
  }

  hide(event) {

    event.preventDefault();
    this.update('hide');
  }

  show(event) {

    event.preventDefault();
    this.update('show');
  }

  update(direction) {

    this.toggleableTarget.classList[direction === 'show' ? 'remove' : 'add']('hidden');
    this.triggerTextTarget.innerText = this.triggerTextTarget.dataset[direction === 'show' ? 'hide' : 'show'];

    classList(this.triggerIconTarget)
      .add(this.triggerIconTarget.dataset[direction === 'show' ? 'hide' : 'show'])
      .remove(this.triggerIconTarget.dataset[direction === 'show' ? 'show' : 'hide']);
  }

  get open() {

    return !this.toggleableTarget.classList.contains('hidden');
  }
}

ToggleController.targets = [
  'trigger',
  'triggerText',
  'triggerIcon',
  'toggleable',
];

export default ToggleController;
