import ApplicationController from '../application_controller';

import { classList } from '../../utils';

class DevisePasswordChecksController extends ApplicationController {

  connect() {

    super.connect();
    this.validate();
  }

  toggleChecks() {

    if (this.inputTarget.value.length) {

      classList(this.checksTarget).remove('hidden');
    } else {

      classList(this.checksTarget).add('hidden');
    }
  }

  validatePassword() {

    if (!this.isActionCableConnectionOpen()) return;

    this.stimulate(
      'PasswordReflex#validate',
      this.inputTarget,
      { body: this.inputTarget.value },
    );
  }

  validate() {

    this.toggleChecks();
    this.validatePassword();
  }
}

DevisePasswordChecksController.debounces = [
  'validatePassword',
];

DevisePasswordChecksController.targets = [
  'input',
  'checks',
];

export default DevisePasswordChecksController;
