import ApplicationController from './application_controller';

const visibilityOptions = target => {

  const options = target.dataset.visibilityOptions || 'true';
  return options.split(' ');
};

// Used within a fieldset, to toggle the visibility of a dependent field based on the value of another
class ToggleDisplayController extends ApplicationController {

  connect() {

    super.connect();
    this.toggle();
  }

  toggle() {

    this.toToggleTargets.forEach(toToggle => {

      const isVisible = visibilityOptions(toToggle).includes(this.togglerTarget.value);

      const toDisable = toToggle.querySelector('[data-target*="toggle-display:toDisable"]');
      if (toDisable) toDisable.disabled = !isVisible;

      return toToggle.classList.toggle('hidden', !isVisible);
    });
  }
}

ToggleDisplayController.targets = [
  'toggler',
  'toToggle',
];

export default ToggleDisplayController;
