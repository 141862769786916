import ApplicationController from './application_controller';

class DependentUnderfixController extends ApplicationController {

  updateContent(event) {

    this.stimulate(
      'DependentUnderfixReflex#update_content',
      event.target,
    );
  }
}

export default DependentUnderfixController;
