import ApplicationController from './application_controller';

import { requestTimeout } from '../utils';

class FieldsetController extends ApplicationController {

  connect() {

    super.connect();

    this.setState();

    if (this.shouldDisableFields && !this.errors) this.disableFields();
  }

  toggle() {

    this.actionTargets.forEach(action => action.classList.toggle('hidden'));
    this.enabled = !this.enabled;
  }

  enableFields() {

    this.dispatch('enable');
    this.setState();

    this.formControlTargets
      .filter(target => target.dataset.fieldsetCanEnable !== 'false')
      .forEach(formControl => formControl.removeAttribute('disabled'));
  }

  disableFields() {

    this.formControlTargets.forEach(formControl => formControl.setAttribute('disabled', ''));
  }

  restoreFields() {

    this.formControlTargets.forEach(formControl => {

      if (Object.prototype.hasOwnProperty.call(this.state, formControl.name)) {

        // eslint-disable-next-line no-param-reassign
        formControl.value = this.changed[formControl.name] || this.state[formControl.name];
        formControl.setAttribute('disabled', '');

        this.element
          .querySelectorAll('.has-error')
          .forEach(el => el.classList.remove('has-error'));

        this.element
          .querySelectorAll('.form-control-error')
          .forEach(el => el.classList.add('hidden'));
      }
    });
  }

  restoreEnabledSiblings({ srcElement }) {

    if (this.element === srcElement || !this.enabled) return;

    this.toggle();
    this.restoreFields();
  }

  saveFields({ currentTarget }) {

    // Make sure form submits
    requestTimeout(
      () => {

        this.disableFields();
        currentTarget.setAttribute('disabled', '');
      },
      0,
    );
  }

  setState() {

    const state = this.formControlTargets.reduce((prev, target) => ({
      ...prev,
      [target.name]: target.value,
    }), {});

    this.state = JSON.stringify(state);
  }

  get enabled() {

    return this.data.get('enabled') === 'true';
  }

  set enabled(value) {

    this.data.set('enabled', value);
  }

  get state() {

    return JSON.parse(this.data.get('state'));
  }

  set state(value) {

    this.data.set('state', value);
  }

  get changed() {

    return JSON.parse(this.data.get('changed'));
  }

  get shouldDisableFields() {

    return this.data.get('shouldDisableFields') === 'disabled';
  }

  get errors() {

    return this.data.get('errors') === 'true';
  }
}

FieldsetController.targets = [
  'action',
  'formControl',
];

export default FieldsetController;
