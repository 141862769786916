import ApplicationController from '../application_controller';
import { requestTimeout } from '../../utils';

class EmploymentContractPresetCoreBenefitTypeFormController extends ApplicationController {

  submit(event) {

    event.preventDefault();
    event.stopPropagation();

    window.scrollTo({ top: '80', behavior: 'smooth' });

    requestTimeout(
      () => this.stimulate(
        'Customers::EmploymentContractPresets::EmploymentContractPresetCoreBenefitTypeReflex#update',
      ),
      300,
    );
  }

  enableSubmitButton() {
    const submitButton = this.submitTarget.querySelector('button[type="submit"]');

    submitButton.disabled = false;
  }
}

EmploymentContractPresetCoreBenefitTypeFormController.targets = [
  'submit',
];

export default EmploymentContractPresetCoreBenefitTypeFormController;
