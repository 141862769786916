import ApplicationController from './application_controller';

import consumer from '../channels/consumer';
import CableReady from 'cable_ready';

class UsersChannelController extends ApplicationController {

  connect() {

    super.connect();

    this.channel = consumer.subscriptions.create(
      {
        channel: 'UsersChannel',
        id: this.id,
      },
      {

        connected() {

          // Called when the subscription is ready for use on the server
        },

        disconnected() {

          // Called when the subscription has been terminated by the server
        },

        received(data) {

          // Called when there's incoming data on the websocket for this channel
          console.log(data);
          if (data.cableReady) CableReady.perform(data.operations);
        },
      },
    );
  }

  disconnect() {

    this.channel.unsubscribe();
  }

  get id() {

    return this.element.dataset.userId;
  }
}

UsersChannelController.values = {
  id: Number,
};

export default UsersChannelController;
