import ApplicationController from '../application_controller';

import snakeCaseKeys from 'snakecase-keys';

class ContractDocumentSignController extends ApplicationController {

  static get eventLoaded() {

    return 'session_view.document.loaded';
  }

  static get eventCompleted() {

    return 'session_view.document.completed';
  }

  connect() {

    super.connect();
    this.listen();
  }

  listen() {

    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    window[eventMethod](messageEvent, event => {
      if (!(event && event.data)) return;

      try {

        const message = JSON.parse(event.data);
        if (!(message && message.event)) return;

        console.log(message.event, message.data, this.uuid, this);
        if (message.data.uuid !== this.uuid) return;
        if (message.event !== ContractDocumentSignController.eventCompleted) return;

        this.stimulate('ContractDocumentReflex#sign!', snakeCaseKeys({ ...message.data }));
        this.modalElement.modal.close();
      } catch (error) {

        console.log(error);
      }
    }, false);
  }

  get uuid() {

    return this.data.get('uuid');
  }

  get modalElement() {

    return document.getElementById(this.element.id.replace('iframe', 'sign'));
  }
}


export default ContractDocumentSignController;
