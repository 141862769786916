import ApplicationController from '../application_controller';

class ValidateCommentFieldController extends ApplicationController {

  valid() {

    return !!this.fieldTarget.value.length;
  }
}

ValidateCommentFieldController.targets = [
  'field',
  'submit',
];

export default ValidateCommentFieldController;
