import ApplicationController from './application_controller';

class FormControlSummaryController extends ApplicationController {

  connect() {

    super.connect();

    this.summarize();
  }

  summarize() {

    this.text = this.output;
    if (this.padded) this.pad();

    this.outputTarget.innerText = this.text;
  }

  pad() {

    this.text = ` ${this.text} `;
  }

  get output() {

    const { value } = this.inputTarget;
    return value.length ? value : this.data.get('default');
  }

  get padded() {

    return this.data.get('padded');
  }
}

FormControlSummaryController.debounces = [
  'summarize',
];

FormControlSummaryController.targets = [
  'input',
  'output',
];

export default FormControlSummaryController;
