/* eslint-disable no-param-reassign */

// 1. User selects file(s)
// 2. For each file a request is made to a presign endpoint to fetch AWS S3 upload parameters
// 3. Files are uploaded asynchronously to AWS S3
// 4. Uploaded file JSON data is written to a hidden field
// 5. Form is submitted instantaneously as it only has to submit the JSON data
// 6. JSON data is assigned to the Shrine attachment attribute (instead of the raw file)

import Core from '@uppy/core';
import FileInput from '@uppy/file-input';
import ProgressBar from '@uppy/progress-bar';
import AwsS3 from '@uppy/aws-s3';
import ms from 'ms';

const fileUpload = (
  {
    id,
    accept,
  },
) => {

  const uppy = Core({
    id,
    debug: true,
    autoProceed: true,
    restrictions: {
      ...(
        !!accept
        && accept !== '*'
        && {
          allowedFileTypes: accept.split(',').map(mime => mime.trim()),
        }
      ),
      maxNumberOfFiles: null,
    },
  });

  // call shrine's presign endpoint mounted on '/presign/s3/params'
  // limit the number of uploads going on at the same time
  uppy.use(AwsS3, {
    limit: 1,
    timeout: ms('1 minute'),
    companionUrl: '/presign',
  });

  return uppy;
};

export const uploadData = file => (
  {
    id: file.meta.key.match(/^cache\/(.+)/)[1],
    storage: 'cache',
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type,
    },
  }
);

export default (
  {
    target,
    input,
    submit,
    onFileAdded,
    onUploadSuccess,
    onUploadError,
    onFileRemoved,
  },
) => {

  input.remove();
  const uppy = fileUpload(input);

  uppy
    .use(
      FileInput,
      {
        target,
        pretty: true,
        inputName: input.name,
        locale: { strings: { chooseFiles: submit.value } },
      },
    )
    .use(
      ProgressBar,
      {
        fixed: true,
        hideAfterFinish: true,
      },
    );

  uppy.on(
    'file-added',
    file => {

      console.log('Uploading file: ', file);
      if (onFileAdded) onFileAdded(file);
    },
  );

  uppy.on(
    'upload-progress',
    (_file, progress) => {

      const { files } = uppy.getState();
      const file = files[_file.id];
      console.log('Uploading file: ', file, ', progress: ', progress);
    },
  );

  uppy.on(
    'upload-success',
    _file => {

      // See https://github.com/transloadit/uppy/issues/2478#issuecomment-693374058
      //  about getting up to date progress
      const { files } = uppy.getState();
      const file = files[_file.id];
      console.log('Successful Upload', file);
      if (onUploadSuccess) onUploadSuccess(file);
    },
  );

  uppy.on(
    'upload-error',
    (file, error, response) => {

      console.log('Failed Upload of file:', file.id);
      console.log(error);
      if (onUploadError) onUploadError(error);
    },
  );

  uppy.on(
    'file-removed',
    (file, reason) => {

      console.log('File Removed', file, reason);
      if (onFileRemoved) onFileRemoved(file);
    },
  );

  return uppy;
};
