// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('moment');

import '../css/application.css';

// Stimulus + Reflex
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import StimulusReflex from 'stimulus_reflex';
import consumer from '../channels/consumer';
import controller from '../controllers/application_controller';
import debounced from 'debounced';
import CableReady from 'cable_ready';

const application = Application.start();

const context = require.context('../controllers', true, /_controller\.js$/);
application.load(definitionsFromContext(context));

const componentContext = require.context('../../components/', true, /(.*)\/.+\.js$/);
application.load(definitionsFromContext(componentContext));

application.consumer = consumer;

StimulusReflex.initialize(application, { controller, isolate: true });
StimulusReflex.debug = process.env.RAILS_ENV === 'development';

debounced.initialize({ ...debounced.events, input: { wait: 1000 } });

CableReady.initialize({ consumer });

// TailwindCSS Stimulus Components
import { Dropdown, Modal, Popover } from 'tailwindcss-stimulus-components';

application.register('dropdown', Dropdown);
application.register('modal', Modal);
application.register('popover', Popover);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = name => images(name, true);

// Usersnap NPS
window.onUsersnapCXLoad = api => {
  api.init();
  window.Usersnap = api;
  window.dispatchEvent(new CustomEvent('usersnap:loaded'));
};
const script = document.createElement('script');
script.defer = 1;
script.src = 'https://widget.usersnap.com/global/load/7c007aa3-10ef-45ff-9422-950168b50486?onload=onUsersnapCXLoad';
document.getElementsByTagName('head')[0].appendChild(script);
