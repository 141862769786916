import ApplicationController from '../application_controller';

class CustomersCustomerBenefitTypesFormSubmitController extends ApplicationController {

  submit(event) {

    event.preventDefault();
    event.stopPropagation();

    this.stimulate('Customers::CustomerBenefitTypeReflex#submit');
  }
}

CustomersCustomerBenefitTypesFormSubmitController.targets = [
  'form',
];

export default CustomersCustomerBenefitTypesFormSubmitController;
