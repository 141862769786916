import ApplicationController from './application_controller';

class EmployeeContractCardController extends ApplicationController {

  updateEmploymentContractDetails(event) {

    this.stimulate(
      'EmployeeContractCardReflex#update_employment_contract_details!',
      event.target,
    );
  }
}

export default EmployeeContractCardController;
