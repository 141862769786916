import ApplicationController from './application_controller';

class PassbaseController extends ApplicationController {

  initialize() {

    window.Passbase.renderButton(
      this.buttonTarget,
      this.publishableApiKey,
      {
        onFinish: identityAccessKey => this.stimulate(
          'PassbaseAuthenticationReflex#create',

          { identityAccessKey },
        ),
        onError: errorCode => console.log(errorCode),
        // onStart: () => {},
        prefillAttributes: { email: this.employee.email },
      },
    );
  }

  // eslint-disable-next-line class-methods-use-this
  openModal() {

    document.getElementsByClassName('passbase-auth-element')[0].click();
  }

  get publishableApiKey() {

    return this.data.get('publishableApiKey');
  }

  get employee() {

    return JSON.parse(this.data.get('employee'));
  }
}

PassbaseController.targets = [
  'button',
];

export default PassbaseController;
