import ApplicationController from './application_controller';

import { classList } from '../utils';

class FormControlListController extends ApplicationController {

  toggleActions({ currentTarget }) {

    this.currentTarget = currentTarget;

    if (currentTarget.value.length) {

      classList(this.saveTarget).remove('hidden');
      classList(this.destroyTarget).add('hidden');
    } else {

      classList(this.saveTarget).add('hidden');
      classList(this.destroyTarget).remove('hidden');
    }
  }

  disableAddAction({ currentTarget }) {

    this.currentTarget = currentTarget;

    classList(this.addTarget).add('opacity-30');
    this.addTarget.setAttribute('disabled', '');
  }

  disableOtherItems({ currentTarget }) {

    this.currentTarget = currentTarget;

    [...this.inputTargets, ...this.saveTargets, ...this.destroyTargets].forEach(el => {

      if (el.dataset.id !== this.currentTarget.dataset.id) classList(el).add('pointer-events-none');
    });
  }

  save({ currentTarget }) {

    this.currentTarget = currentTarget;

    this.stimulate(
      'FormControlListReflex#save',
      this.inputTarget,
    );
  }

  get inputTarget() {

    return this.inputTargets.find(el => el.dataset.id === this.currentTarget.dataset.id);
  }

  get saveTarget() {

    return this.saveTargets.find(el => el.dataset.id === this.currentTarget.dataset.id);
  }

  get destroyTarget() {

    return this.destroyTargets.find(el => el.dataset.id === this.currentTarget.dataset.id);
  }
}

FormControlListController.targets = [
  'input',
  'add',
  'save',
  'destroy',
];

export default FormControlListController;
