import ApplicationController from './application_controller';

class FormControlLabelController extends ApplicationController {

  connect() {

    super.connect();
    this.setLabel();
  }

  setLabel() {

    this.label.innerText = this.output;
  }

  get label() {

    return this.outputTarget.labels[0];
  }

  get output() {

    const { value } = this.inputTarget;
    return this.options[value];
  }

  get options() {

    return JSON.parse(this.data.get('options'));
  }
}

FormControlLabelController.targets = [
  'input',
  'output',
];

export default FormControlLabelController;
