import ApplicationController from './application_controller';

import {
  classList,
  requestTimeout,
} from '../utils';

class AlertsController extends ApplicationController {

  connect() {

    super.connect();

    this.toggleClass = this.data.get('class') || 'invisible';
  }

  initialize() {

    super.initialize();

    this.toggleIn();
    if (this.timeout > 0) this.toggleOut();
  }

  toggleIn() {

    requestTimeout(
      () => classList(this.wrapperTarget).remove(this.toggleClass),
      this.delay,
    );
  }

  toggleOut() {

    requestTimeout(
      () => this.dismiss(),
      this.timeout,
    );
  }

  dismiss() {

    classList(this.wrapperTarget).add(this.toggleClass);

    requestTimeout(
      () => this.wrapperTarget.remove(),
      this.transitionDuration,
    );
  }

  get delay() {

    return this.data.get('delay') || 0;
  }

  get timeout() {

    return this.data.get('timeout') || 7000;
  }

  get transitionDuration() {

    return this.data.get('transition-duration') || 300;
  }
}

AlertsController.targets = ['wrapper'];

export default AlertsController;
