import ApplicationController from './application_controller';

import { classList } from '../utils';

class FormSubmitButtonController extends ApplicationController {

  toggle() {
    if (this.dirty) {

      this.submitTarget.disabled = false;
      classList(this.submitTarget).add('btn-primary').remove('btn-primary-disabled');
    } else {

      this.submitTarget.disabled = true;
      classList(this.submitTarget).remove('btn-primary').add('btn-primary-disabled');
    }
  }

  get dirty() {
    return this.fieldTargets.some(target => target.value !== target.defaultValue);
  }
}

FormSubmitButtonController.targets = [
  'field',
  'submit',
];

export default FormSubmitButtonController;
