import ApplicationController from '../application_controller';

class EmployeesCountrySelectController extends ApplicationController {

  connect() {

    super.connect();

    this.setInitialState();
  }

  change({ currentTarget }) {

    this.idTarget.value = currentTarget.dataset.id;
    this.codeTarget.value = currentTarget.dataset.code;
    this.optionTargets.forEach(opt => opt.classList.toggle('country-select-active', opt === currentTarget));
  }

  setInitialState() {

    let selected = null;

    if (this.optionTargets.length === 1) {

      [selected] = this.optionTargets;
    } else {

      const countryCode = (new URL(window.location)).searchParams.get('country_code');
      if (countryCode) selected = this.optionTargets.find(target => target.dataset.code.toLowerCase() === countryCode);
    }

    if (!selected) return;

    this.change({ currentTarget: selected });
  }
}

EmployeesCountrySelectController.targets = [
  'id',
  'code',
  'option',
];

export default EmployeesCountrySelectController;
