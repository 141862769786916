import ApplicationController from './application_controller';

class NpsController extends ApplicationController {

  connect() {

    super.connect();

    this.element[this.identifier] = this;
  }

  open() {
    if (this.npsOpen) {
      window.Usersnap.logEvent('npsOpen');
    }
  }

  get npsOpen() {
    return this.element.dataset.npsOpen === 'true';
  }
}

NpsController.targets = [];

export default NpsController;
