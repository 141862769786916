import ApplicationController from './application_controller';

import { classList } from '../utils';

class CommentFieldController extends ApplicationController {

  handleChange() {

    if (!this.hasEditTarget) {

      classList(this.submitTarget)[this.fieldTarget.value.length ? 'remove' : 'add']('hidden');
    }
  }

  submit() {

    this.stimulate(
      'CommentReflex#submit',
      this.fieldTarget,
      { body: this.fieldTarget.value },
    );
  }
}

CommentFieldController.targets = [
  'field',
  'edit',
  'submit',
];

export default CommentFieldController;
