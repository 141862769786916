import ApplicationController from './application_controller';

// Similar to a carousel without animation
// List of `ViewComponent::Ui::Pill`s switching visibility of `ViewComponent::List::Checks.
//  Example: https://www.figma.com/file/ipRKOtytdGeWbQsuFPkfFR/Benefits-v2?node-id=480%3A18585
// NOTE: This on the face of it is similar to `ToggleController` but is used in another context.
//  We have a number of controllers that deal with adding/removing `hidden` class which could probably be simplified.
//  Tidy-up required for these utility controllers, and to be more behaviour- rather than context-driven.
class SwitcherController extends ApplicationController {

  static selector(id) {

    return `[data-switcher-target='toggleable'][data-switcher-id='${id}']`;
  }

  connect() {

    super.connect();

    this.toggleClass = this.data.get('class') || 'hidden';
  }

  show(event) {

    event.preventDefault();

    const selector = SwitcherController.selector(event.currentTarget.dataset.switcherId);
    const currentToggleable = this.element.querySelector(selector);
    this.toggleableTargets.forEach(toggleable => {

      toggleable.classList.toggle(this.toggleClass, toggleable !== currentToggleable);
    });
  }
}

SwitcherController.targets = [
  'trigger',
  'toggleable',
];

export default SwitcherController;
