import ApplicationController from '../application_controller';
import { classList } from '../../utils';

class DevisePasswordToggleShowController extends ApplicationController {

  toggleButton() {

    if (this.inputTarget.value.length) {

      classList(this.showToggleTarget).add('text-blue').remove('text-grey-border');
    } else {

      this.inputTarget.type = 'password';
      this.showToggleTarget.innerText = 'Show';
      classList(this.showToggleTarget).add('text-grey-border').remove('text-grey-blue');
    }
  }

  toggleShowPassword(event) {

    event.preventDefault();
    event.stopPropagation();

    if (!this.inputTarget.value.length) return;

    if (this.inputTarget.type === 'text') {

      this.inputTarget.type = 'password';
      this.showToggleTarget.innerText = 'Show';
    } else {

      this.inputTarget.type = 'text';
      this.showToggleTarget.innerText = 'Hide';
    }
  }
}

DevisePasswordToggleShowController.targets = [
  'input',
  'showToggle',
];

export default DevisePasswordToggleShowController;
