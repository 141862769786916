import ApplicationController from './application_controller';

import s3FileUpload, { uploadData } from '../utils/s3FileUpload';
import snakeCaseKeys from 'snakecase-keys';
import { requestTimeout } from '../utils';

const dispatch = (el, event = 'change') => (

  new Promise(resolve => {
    requestTimeout(
      () => {
        el.dispatchEvent(new Event(event));
        resolve('resolved');
      },
      50,
    );
  })
);

class FileUploadController extends ApplicationController {

  initialize() {

    super.initialize();

    this.onFileAdded = this.onFileAdded.bind(this);
    this.onUploadSuccess = this.onUploadSuccess.bind(this);
    this.onFileRemoved = this.onFileRemoved.bind(this);

    this.currentTargets = {};
    this.uppy = s3FileUpload({
      target: this.element,
      input: this.inputTarget,
      submit: this.submitTarget,
      onFileAdded: this.onFileAdded,
      onUploadSuccess: this.onUploadSuccess,
      onUploadError: this.onUploadError,
      onFileRemoved: this.onFileRemoved,
    });
  }

  disconnect() {

    super.disconnect();
    this.uppy.close();
  }

  proxyClick() {

    const button = this.element.getElementsByClassName('uppy-FileInput-input')[0];
    button.click();
  }

  remove(event) {

    const element = event.currentTarget.closest('[data-file-upload-target="element"]');
    const { id } = element.dataset;
    this.uppy.removeFile(id);
  }

  appendUploadInput(id) {

    const documentFragment = this.templateTarget.content.cloneNode(true);

    const fileData = documentFragment.querySelector('[data-file-upload-target="fileData"]');
    fileData.dataset.id = id;

    const file = documentFragment.querySelector('[data-file-upload-target="file"]');
    file.dataset.id = id;
    file.name = file.name.replace('[0]', `[${this.count}]`);

    this.filesTarget.appendChild(documentFragment);
    this.filesTarget.dataset.count = this.fileTargets.length;

    return { fileData, file };
  }

  async onFileAdded(data) {

    if (this.hasButtonTarget) this.buttonTarget.disabled = true;

    const { id } = data;
    const { fileData, file } = this.appendUploadInput(id);

    this.currentTargets = {
      ...this.currentTargets,
      [id]: { fileData, file },
    };

    fileData.value = JSON.stringify(snakeCaseKeys(data));
    await dispatch(fileData);
  }

  async onUploadSuccess(data) {

    if (this.hasButtonTarget) this.buttonTarget.disabled = false;

    const { id } = data;
    const { fileData, file } = this.currentTargets[id];
    fileData.value = JSON.stringify(snakeCaseKeys(data));
    file.value = JSON.stringify(uploadData(data));
    await dispatch(file);

    if (this.auto) this.submitTarget.click();
  }

  onUploadError() {

    if (this.hasButtonTarget) this.buttonTarget.disabled = false;
  }

  async onFileRemoved(data) {

    const { id } = data;
    const { fileData, file } = this.currentTargets[id];

    this.filesTarget.dataset.removed = id;
    this.filesTarget.dataset.count = this.count - 1;
    await dispatch(this.filesTarget);

    fileData.remove();
    file.remove();
    delete this.currentTargets[id];
  }

  get multiple() {

    return 'multiple' in this.filesTarget.dataset;
  }

  get auto() {

    return 'auto' in this.submitTarget.dataset;
  }

  get count() {

    return parseInt(this.filesTarget.dataset.count, 10);
  }
}

FileUploadController.targets = [
  'input',
  'template',
  'fileData',
  'file',
  'files',
  'submit',
  'button',
  'element',
];

export default FileUploadController;
