import ApplicationController from '../application_controller';

class ModalTriggerController extends ApplicationController {

  open(e) {

    const disableTransition = !!this.modalToCloseElement;
    if (this.modalToCloseElement) this.modalToCloseElement.modal.close(e, disableTransition);
    if (this.modalElement) this.modalElement.modal.open(e, disableTransition);
  }

  get domId() {

    return this.data.get('dom-id');
  }

  get modalElement() {

    return document.getElementById(this.domId);
  }

  get closeDomId() {

    return this.data.get('close-dom-id');
  }

  get modalToCloseElement() {

    return document.getElementById(this.closeDomId);
  }
}


export default ModalTriggerController;
