/**
 * Chainable classlist manipulation
 * @param {object} el The DOM element
 */
export function classList(el) {
  const list = el.classList;

  return {
    toggle(c) {
      list.toggle(c);
      return this;
    },
    add(c) {
      list.add(c);
      return this;
    },
    remove(c) {
      list.remove(c);
      return this;
    },
  };
}

export const initials = (firstName, lastName) => (
  `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`
);

/**
 * String manipulation helpers analogous to Rails' equivalents
 * @param {string} str The string
 */
export const hyphenize = str => str
  .split('_')
  .join('-')
  .replace(/([a-z])([A-Z])/g, '$1-$2')
  .toLowerCase();

export const camelize = str => str
  .replace(
    /[_-]([a-z])/g,
    g => g[1].toUpperCase(),
  );

export const underscorize = str => str
  .replace(
    /([a-z][A-Z])/g,
    g => `${g[0]}_${g[1].toLowerCase()}`,
  );

export const constantize = str => str
  .replace(
    /([a-z][A-Z])/g,
    g => `${g[0]}_${g[1]}`,
  )
  .toUpperCase();

export const humanize = str => str
  .replace(/[_-]/g, ' ')
  .replace(/(?:^|\s)\S/g, a => a.toUpperCase());

export const titleize = str => str
  .toLowerCase()
  .replace(/(?:^|\s|-)\S/g, m => m.toUpperCase());

/**
 * As Rails' to_sentence
 * @param {array} arr The list of strings
 */
export const toSentence = arr => [
  arr.slice(0, -1).join(', '),
  arr.slice(-1)[0],
].join(arr.length < 2 ? '' : ' and ');

/**
 * https://leastbad.com/stimulus-power-move
 * @param str
 * @returns {string}
 */
export const camelCaseStimulusIdentifier = str => str
  .split('--')
  .slice(-1)[0]
  .split(/[-_]/)
  .map(w => w.replace(/./, m => m.toUpperCase()))
  .join('')
  .replace(/^\w/, c => c.toLowerCase());

/**
 * Helper when SSR'ing, if a library/function requires the DOM (window, navigator etc)
 *   then it can only be rendered on the client.
 */
export const canUseDOM = () => (
  !!(typeof window !== 'undefined' && window.document && window.document.createElement)
);

/**
 * Behaves the same as setTimeout except uses requestAnimationFrame()
 * @param {function} fn The callback function
 * @param {int} delay The delay in milliseconds
 */
export const requestTimeout = (fn, delay) => {

  const start = new Date().getTime();
  const handle = {};

  const loop = () => {

    const current = new Date().getTime();
    const delta = current - start;
    return delta >= delay ? fn.call() : handle.value = requestAnimationFrame(loop);
  };

  handle.value = requestAnimationFrame(loop);
  return handle;
};

/**
 * Behaves the same as setInterval except uses requestAnimationFrame()
 * @param {function} fn The callback function
 * @param {int} delay The delay in milliseconds
 */
const requestInterval = (fn, delay) => {

  let start = new Date().getTime();
  const handle = {};

  const loop = () => {

    const current = new Date().getTime();
    const delta = current - start;

    if (delta >= delay) {

      fn.call();
      start = new Date().getTime();
    }

    handle.value = requestAnimationFrame(loop);
  };

  handle.value = requestAnimationFrame(loop);

  return handle;
};

export const safeParseJSON = (str, fallback = []) => {

  try {

    return JSON.parse(str);
  } catch (error) {

    return fallback;
  }
};
