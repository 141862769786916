import { Modal } from 'tailwindcss-stimulus-components';
import StimulusReflex from 'stimulus_reflex';

import { requestTimeout, camelCaseStimulusIdentifier } from '../../utils';

class ModalController extends Modal {

  connect() {

    super.connect();
    this.disableTransitionClass = this.data.get('disable-transition-class') || 'disable-transition';

    StimulusReflex.register(this);

    this.element[camelCaseStimulusIdentifier(this.identifier)] = this;
  }

  disableTransition() {

    this.containerTarget.classList.add(this.disableTransitionClass);
    requestAnimationFrame(() => this.containerTarget.classList.remove(this.disableTransitionClass)); // async
  }

  open(e, disableTransition = false) {

    // Note: Overriding this to test for e seems better than simulating a new MouseEvent("click")
    if (e) {

      e.preventDefault();
      e.stopPropagation();
      e.target.blur();
    }

    // Lock the scroll and save current scroll position
    this.lockScroll();

    // Unhide the modal
    if (disableTransition) this.disableTransition();

    this.containerTarget.classList.remove(this.toggleClass);

    if (!this.hasIframeTarget) return;

    if (this.element.dataset.reflexOnOpen) {

      this.stimulate(this.element.dataset.reflexOnOpen);
    } else {

      this.refreshIframe();
    }
  }

  close(e, disableTransition = false) {

    if (e && this.preventDefaultActionClosing) e.preventDefault();

    // Unlock the scroll and restore previous scroll position
    this.unlockScroll();

    // Hide the modal
    if (disableTransition) this.disableTransition();

    this.containerTarget.classList.add(this.toggleClass);

    // Remove the background
    if (this.background) this.background.remove();

    // Reflex on close if set
    if (this.element.dataset.reflexOnClose) this.stimulate(this.element.dataset.reflexOnClose);
  }

  refreshIframe() {

    requestTimeout(
      () => this.iframe.src = this.iframeSrc,
      0,
    );
  }

  get iframe() {

    return this.iframeTarget.querySelector('iframe');
  }

  get iframeSrc() {

    if (!this.iframe) return null;

    return this.iframe.src;
  }
}

ModalController.targets = [...Modal.targets, 'iframe'];

export default ModalController;
